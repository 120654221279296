import React, { FC } from 'react';

import TopFooter from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/TopFooter';
import BottomFooter from 'gatsby-theme-gaviscon/src/components/Footer/BottomFooter/BottomFooter';
import MiddleFooter from 'gatsby-theme-gaviscon/src/components/Footer/MiddleFooter/MiddleFooter';

import 'gatsby-theme-gaviscon/src/components/Footer/Footer.scss';

const Footer: FC<PageContent.IFooter> = ({
  logo,
  support,
  address,
  navigation,
  bottomLinks,
  copyright,
  middleCopyright,
  sponsors,
  image,
  lsSettings,
}) => (
  <div className="gs-footer">
    <TopFooter {...{ logo, support, address, navigation, lsSettings }} />
    <MiddleFooter {...{ middleCopyright, sponsors, image, navigation }} />
    <BottomFooter links={bottomLinks} copyright={copyright} />
  </div>
);

export default Footer;
