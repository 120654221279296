import React, { FC } from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import 'gatsby-theme-gaviscon/src/components/Footer/MiddleFooter/MiddleFooter.scss';
import { IMiddleFooter } from './model';
import './MiddleFooterOverride.scss';

const MiddleFooter: FC<IMiddleFooter> = ({ navigation, sponsors, middleCopyright, image }) => {
  const { isMobile, isNotMobile } = useScreenRecognition();

  const renderedSponsorsList = sponsors?.map(({ properties: { logo, href } }) => {
    const logoContent = logo[0].properties.logo?.fallbackUrl ? (
      <GatsbyImage
        className="gs-footer-middle__sponsor-logo"
        fluid={logo[0].properties.logo}
        alt={logo[0].properties.altText}
      />
    ) : null;
    const wrappedLogoContent = href ? (
      <a href={href} target="_blank" rel="noreferrer noopener">
        {logoContent}
      </a>
    ) : (
      logoContent
    );

    return <li key={logo[0].properties.logo?.fallbackUrl}>{wrappedLogoContent}</li>;
  });

  return (
    <Container fluid>
      <div className="gs-footer-middle">
        {isMobile ? (
          <DangerouslySetInnerHtml
            className="gs-footer-middle__copyright gs-footer-middle__copyright--sm"
            html={middleCopyright}
          />
        ) : null}
        <div className="gs-footer-middle__content">
          {isNotMobile ? (
            <DangerouslySetInnerHtml
              className="gs-footer-middle__copyright gs-footer-middle__copyright--lg"
              html={middleCopyright}
            />
          ) : null}
          <div className="gs-footer-links__nav">
            {navigation?.length > 0 &&
              navigation.map(({ properties: { title, links } }) => (
                <div className="gs-footer-links__nav-group" key={title}>
                  <DangerouslySetInnerHtml
                    className="gs-footer-links__title"
                    element="h4"
                    html={title}
                  />
                  <ul className="gs-footer-links__nav-list">
                    {links.map(({ properties: { link: [{ name, url }] } }) => (
                      <li key={name}>
                        <Link to={url}>
                          <span className="gs-footer-links__nav-list-label">{name}</span>
                          <IconCustom icon="chevron-right" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
          <div className="gs-footer-middle__wrap">
            <div className="gs-footer-middle__image gs-footer-middle__placeholder" />
            {sponsors?.length ? (
              <ul className="gs-footer-middle__sponsors">{renderedSponsorsList}</ul>
            ) : null}
            <div className="gs-footer-middle__image">
              {image?.fallbackUrl ? (
                <GatsbyImage fluid={image} aria-hidden="true" alt="Footer decoration" />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MiddleFooter;
