import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './TopFooterOverride.scss';

import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import LanguageSelector from 'gatsby-theme-gaviscon/src/components/common/LanguageSelector';
import Support from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/components/Support';
import Address from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/components/Address';

import 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/TopFooter.scss';
import { ITopFooter } from 'gatsby-theme-gaviscon/src/components/Footer/TopFooter/model';

const TopFooter: FC<ITopFooter> = ({ logo, address, support, lsSettings }) => (
  <div className="gs-footer-top">
    <Container fluid>
      {logo?.length ? (
        <GatsbyImage
          className="gs-footer-top__logo"
          fluid={logo[0].properties.logo}
          alt={logo[0].properties.altText}
        />
      ) : null}
      <Row>
        {support?.length && address?.length ? (
          <Col lg="4">
            {parseBoolean(lsSettings.inFooter) ? (
              <LanguageSelector type="list" {...lsSettings} />
            ) : null}
            {support.length ? <Support {...{ support }} /> : null}
            {address.length ? <Address {...{ address }} /> : null}
          </Col>
        ) : null}
      </Row>
    </Container>
  </div>
);

export default TopFooter;
